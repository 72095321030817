.menu-container {
  display: flex;
  justify-content: space-between;
}

.menu-scroll {
  width: 330px;
  margin-top: 25px;
  position: sticky;
  top: 100px;
  align-self: flex-start;
  margin-bottom: 18%;
}

.menu-item-scroll {
  cursor: pointer;
  margin-bottom: 10px;
  color: #a5a39f;
}

.text-menu {
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 60px;
  transition: 300ms;
}

.text-menu.text-active {
  font-weight: 400;
}

.menu-item.active {
  color: #212121 !important;
}

.text-menu:hover {
  color: #5e5e5e;
}

.text-menu.text-active:hover {
  color: #212121;
}

.menu-item-scroll.active {
  font-weight: 500 !important;
  color: #212121 !important;
}

.menu-content {
  padding: 20px;
  width: 90%;
}

.menu-category-section {
  margin-bottom: 60px;
}

.category-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.menu-images-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
}

.menu-item-image {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}

.background-color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.image-content {
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
}

.first-image,
.second-image,
.fourth-image,
.fifth-image {
  width: 362px;
  height: 482px;
}

.second-div,
.fourth-div {
  transform: translateY(80px);
}
.second-desc,
.fourth-desc {
  transform: translateY(80px);
}

.third-div,
.sixth-div {
  transform: translateY(80px);
  margin-bottom: 80px;
}

.third-image,
.sixth-image {
  width: 612px;
  height: 400px;
}

.image-subtitle {
  font-size: 16px;
  color: #555;
}

/* carousel */
.carousel-container {
  position: relative;
  width: 100%;

  max-width: 970px;
  /* margin: 0 auto; */
}

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel-items {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  width: 275px;
  margin: 0 8px;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1;
}

/* .prev-icon {
  right: -100px;
} */

/* .next-icon {
  left: -125px;
} */

@media (max-width: 1180px) {
  .menu-images-grid {
    gap: 50px;
  }

  .first-image,
  .second-image,
  .fourth-image,
  .fifth-image {
    width: 302px;
    height: 422px;
  }
  .third-image,
  .sixth-image {
    width: 602px;
    height: 340px;
  }
}

.first-image-mob {
  width: 267px;
  height: 356px;
}
.second-image-mob {
  width: 247px;
  height: 325px;
}
.third-image-mob {
  width: 290px;
  height: 189px;
}
.fourth-image-mob {
  width: 249px;
  height: 296px;
}
/* .fifth-image-mob {
  width: ;
  height: ;
}
.sixth-image-mob {
  width: ;
  height: ;
} */
