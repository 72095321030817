@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Josefin Sans", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 19.2px;
  overflow-x: hidden !important;
  color: #212121;
  background-color: #f8f7f0;
}

.playfair {
  font-family: "Playfair Display", sans-serif;
}

.bH1 {
  font-family: "Playfair Display", sans-serif;

  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
}

.bH2 {
  font-size: 45px;
  line-height: 69px;
}
.bP2 {
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
}
.made {
  font-weight: 300;
  font-size: 12.5px;
  line-height: 132.5%;
  color: #6d6d6d;
}

@media (min-width: 768px) {
  .bH1 {
    font-size: 45px;
    line-height: 60px;
  }
  .bH2 {
    font-size: 45px;
    line-height: 69px;
  }

  .bP2 {
    font-weight: 300;
    font-size: 18px;
    line-height: 132.5%;
  }
}
