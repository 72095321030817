*::-webkit-scrollbar {
  display: none;
}

.drawer-open {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1234;
  border: solid 1px #212121;
  border-radius: 100px;
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0);
  margin-right: 30px;
}

.drawer-open span {
  width: 0px !important;
}

.drawer-open span,
.drawer-open span:before,
.drawer-open span:after {
  content: "";
  height: 1px;
  width: 12px;
  background-color: currentColor;
  transition: 400ms 350ms;
  position: absolute;
  display: flex;
  justify-content: center;
}

.drawer-open span:before {
  bottom: 5px;
}

.drawer-open span:after {
  top: 5px;
}

#drawer-input:checked ~ .drawer-open span {
  width: 0px;
  transition: 100ms;
}

#drawer-input:checked ~ .drawer-open span::before {
  bottom: 0;
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0);
  transform: rotate(45deg);
}

#drawer-input:checked ~ .drawer-open span::after {
  top: 0;
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0);
  transform: rotate(-45deg);
}

#drawer-input:checked ~ .drawer-open {
  margin-right: 50px;
  margin-top: 20px;
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0);
}
#drawer-input:checked ~ .nav-content {
  transition: all 500ms cubic-bezier(0.1, 0, 0.8, 0);
  height: 432px;
  /* height: 320px; */
  background-color: #f8f7f0;
  width: calc(100vw - 60px);
  margin: 15px 30px;
  border-radius: 10px;
}

#drawer-input:checked ~ .nav-content .items {
  opacity: 1;
  transition: 1500ms 600ms;
}

#drawer-input:checked ~ .nav-content .item {
  opacity: 1;
  transition: 1500ms 600ms;
}

/* .hidden {
  display: none;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 80px;
  width: 100vw;
  right: 0px;
  z-index: 999;
}

.items {
  width: 100%;
  min-width: 300px;
}

.item {
  opacity: 0;
  transition: 350ms;
}

.item a {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.item a p {
  transition: 150ms;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 132.5%;
}

.nav-content {
  position: fixed;
  height: 80px;
  width: 100vw;
  left: 0;
  top: 0;
  transition: 400ms 350ms;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #f8f7f0;
  box-shadow: 0px 4px 10px rgba(97, 97, 97, 0.06);
}

.nav-content .items {
  opacity: 0;
  transition: 350ms;
  margin-top: 75px;
  padding-inline-start: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 132.5%;
  border: solid 1px;
  border-radius: 10px;
}

.items {
  background-color: #f8f7f0;
}
@media (min-width: 1024px) {
  #drawer-input:checked ~ .nav-content {
    transition: all 500ms cubic-bezier(0.1, 0, 0.8, 0);
    height: 300px;
  }
  .nav-content .items {
    margin-top: 0px;
    font-size: 23px;
  }
  .items {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
  }
}

@media (min-width: 1110px) {
  .nav-content .items {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
  #drawer-input:checked ~ .nav-content {
    transition: all 500ms cubic-bezier(0.1, 0, 0.8, 0);
    /* font-size: 28px; */
  }

  .nav-content .items {
    font-size: 28px;
  }
}

.navbar-visible {
  visibility: visible;
  transform: translateY(0px);
  /* transform: translateX(-100px); */
  transition: transform 0.6s ease-in-out, visibility 0s linear 0s;
}

.navbar-hidden {
  /* visibility: hidden; */
  transform: translateY(-100px);
  transition: transform 0.6s ease-in-out, visibility 0s linear 0.6s;
}

.logo-header {
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0); /* Transición suave */
}
#drawer-input:checked ~ .logo-header {
  margin-left: 50px;
  margin-top: 20px;
  transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.85, 0);
}

.footer-text {
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
}

@media (max-width: 1024px) {
  #drawer-input:checked ~ .logo-header {
    margin-left: 25px;
    margin-top: 26px;
  }
  #drawer-input:checked ~ .drawer-open {
    margin-right: 50px;
    margin-top: 26px;
  }
  #drawer-input:checked ~ .nav-content .items {
    border: none;
  }

  #drawer-input:checked ~ .nav-content {
    border: solid 0.5px #a5a39f;
  }
}
