.image-wrapper {
  position: relative;
  overflow: hidden;
  height: 680px;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
}

.color-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #3498db;
  transition: opacity 0.5s ease-in-out;
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f8f7f0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.8s ease-out;
  z-index: 999999;
}

.loader {
  opacity: 1;
  transition: opacity 1.2s ease-out; /* Transición suave para el fade-out */
}

.loader.fade-out {
  opacity: 0;
  pointer-events: none; /* Opcional: desactiva eventos de usuario durante el fade-out */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logo {
  animation: spin 1.2s linear infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.none-cursor {
  cursor: none !important;
  overflow-y: hidden !important;
}
